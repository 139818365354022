import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { getCimiteri } from "@services/CimiteriService";
import { apiRefreshToken } from "@services/api/interceptors";
import {
    deleteAccessToken,
    getAccessToken,
    getCodiceTenant,
    getRefreshToken,
    setAccessToken,
    setComuneId,
    setRefreshToken,
} from "@services/auth/AccessTokenService";

import { useAuth } from "@context/AuthContext";

import { comuneAttivoState } from "@stores/geographicLists";
import {
    ActionSidenavTree,
    actionSidenavTreeState,
    cimiteriListState,
    idCimiteroAttivoState,
    idMunicipalityState,
    loadingSidenavTreeState,
    reloadSidenavTreeState,
} from "@stores/municipality";

import { IMunicipality } from "@models/Comune";

import FormGroup from "@components/Form/FormGroup";
import SelectInput from "@components/Form/Input/SelectInput";

const MunicipalityForm: FC<{ data: any }> = ({ data }) => {
    const { user } = useAuth();
    const { setFieldValue, values } = useFormikContext<IMunicipality>();

    const navigate = useNavigate();

    const setCimiteriList = useSetRecoilState(cimiteriListState);
    const setActionSidenavTree = useSetRecoilState(actionSidenavTreeState);
    const setIdMunicipality = useSetRecoilState(idMunicipalityState);
    const setReloadSidenavTreeState = useSetRecoilState(reloadSidenavTreeState);
    const setLoadingSidenavTreeState = useSetRecoilState(loadingSidenavTreeState);
    const setComuneAttivoStateState = useSetRecoilState(comuneAttivoState);
    const setIdCimiteroAttivoState = useSetRecoilState(idCimiteroAttivoState);

    const {
        data: cimiteri,
        run,
        loading,
    } = useRequest(getCimiteri, {
        manual: true,
        onSuccess: setCimiteriList,
    });

    const { municipality } = values;

    useEffect(() => {
        if (!municipality) setFieldValue("municipality", user?.comuneAttivo.id);

        if (municipality) {
            const accessToken = getAccessToken();
            const refreshToken = getRefreshToken();
            const codiceTenant = getCodiceTenant();

            apiRefreshToken({ accessToken, refreshToken, idComune: municipality, codiceTenant })
                .then((rs) => {
                    setAccessToken(rs.data.accessToken);
                    setRefreshToken(rs.data.refreshToken);
                    setComuneId(municipality);
                    setIdMunicipality(municipality);

                    setActionSidenavTree(ActionSidenavTree.CambioComune);
                    const comuneAttivo = rs?.data?.comuneAttivo?.descr ?? "";
                    setComuneAttivoStateState(comuneAttivo);
                    document.title = comuneAttivo;

                    run();
                })
                .catch((_) => {
                    deleteAccessToken();
                    navigate("/login?refreshToken=expired");
                });
        }
        // eslint-disable-next-line
    }, [municipality, user, setFieldValue]);

    useEffect(() => {
        setLoadingSidenavTreeState(loading);
    }, [loading]);

    return (
        <FormGroup name='municipality' className='mt-0'>
            <SelectInput
                name='municipality'
                data={data}
                valueKey='id'
                labelKey='descr'
                cleanable={false}
                searchable={false}
                placeholder='Seleziona comune'
                onChange={() => {
                    setIdCimiteroAttivoState(null);
                    navigate("/cimitero");
                    setReloadSidenavTreeState(false);
                }}
            />
        </FormGroup>
    );
};

export default MunicipalityForm;
