import { compareAsc } from "date-fns";
import React, { FC, useMemo } from "react";
import { Badge } from "rsuite";

import { Contratto } from "@models/Contratto";
import { TombaContratto } from "@models/Tomba";

interface ContrattoBadgeProps {
    contract: Contratto | TombaContratto;
}

const ContrattoBadge: FC<ContrattoBadgeProps> = ({ contract }) => {
    const tombeScadute = useMemo(() => {
        if ("tombe" in contract) {
            return contract?.tombe?.filter((tomba) => {
                return tomba?.dataScadenza ? compareAsc(new Date(tomba.dataScadenza), new Date()) < 0 : undefined;
            });
        }

        return undefined;
    }, [contract]);

    const tombaScaduta = useMemo(() => {
        if ("dataScadenza" in contract) {
            return contract?.dataScadenza ? compareAsc(new Date(contract.dataScadenza), new Date()) < 0 : undefined;
        }

        return false;
    }, [contract]);

    return (
        <section>
            {contract?.storico && !contract?.dataAnnullamento && (
                <Badge content='Storico' className='w-fit bg-athens-500 text-primary-500 font-bold' />
            )}

            {(("tombe" in contract &&
                tombeScadute?.length === contract?.tombe?.length &&
                !contract?.dataAnnullamento &&
                !contract?.storico) ||
                (tombaScaduta && !contract?.dataAnnullamento && !contract?.storico)) && (
                <Badge color='orange' content='Scaduto' className='w-fit' />
            )}

            {"tombe" in contract &&
                tombeScadute &&
                tombeScadute?.length > 0 &&
                tombeScadute?.length < contract?.tombe?.length &&
                !contract?.dataAnnullamento &&
                !contract?.storico && <Badge color='orange' content='Parzialmente scaduto' className='w-fit' />}

            {"tombe" in contract && tombeScadute?.length === 0 && !contract?.dataAnnullamento && !contract?.storico && (
                <Badge color='green' content='Attivo' className='w-fit' />
            )}

            {!tombaScaduta && !tombeScadute && !contract?.dataAnnullamento && !contract?.storico && (
                <Badge color='green' content='Attivo' className='w-fit' />
            )}

            {contract?.dataAnnullamento && <Badge color='red' content='Annullato' className='w-fit' />}
        </section>
    );
};

export default ContrattoBadge;
