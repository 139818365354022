import { atom } from "recoil";

import { Provincia, Regione, Stato } from "@models/Comune";

const statiListState = atom<Stato[]>({
    key: "statiListState",
    default: [],
});

const provinceListState = atom<Provincia[]>({
    key: "provinceListState",
    default: [],
});

const regioniListState = atom<Regione[]>({
    key: "regioniListState",
    default: [],
});

const comuneAttivoState = atom<string>({
    key: "comuneAttivoState",
    default: "",
});

export { statiListState, provinceListState, regioniListState, comuneAttivoState };
