import { useField } from "formik";
import * as React from "react";
import { FC } from "react";
import { InputNumber as RsInputNumber, InputNumberProps as RsInputNumberProps } from "rsuite";

interface NumberInputPros extends Omit<RsInputNumberProps, "form"> {
    name: string;
}

const NumberInput: FC<NumberInputPros> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    return (
        <RsInputNumber
            {...field}
            onChange={(newValue, event) => {
                helpers.setValue(Number(newValue));
                props.onChange?.(newValue, event);
            }}
            {...props}
        />
    );
};

export default NumberInput;
