import { useRequest } from "ahooks";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Nav } from "rsuite";

import { getGrafica, getSettoreCimitero, getTombeSettore } from "@services/CimiteriService";

import { SettoreCimitero } from "@models/SettoreCimitero";
import { Tomba } from "@models/Tomba";

import SottoSettoriViewWrapper from "@modules/cimiteri/pages/_components/SottoSettoriViewWrapper";
import TombeSettoreViewWrapper from "@modules/cimiteri/pages/_components/TombeSettoreViewWrapper";
import GraphicView from "@modules/cimiteri/pages/settori/GraphicView";

import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";

import SelezionaSettore from "@components/Tombe/SelezionaSettore";

import { MapProvider } from "@openLayersMap/Map/MapContext";

const SelezionaTomba = ({
    setTombe,
    setGetTomba,
    disabledGrafica,
    multipleOptions = false,
}: {
    setTombe?: Dispatch<SetStateAction<number[] | undefined>>;
    setGetTomba?: Dispatch<SetStateAction<Tomba | undefined>>;
    disabledGrafica?: boolean;
    multipleOptions?: boolean;
}): JSX.Element => {
    const [getTombaId, setGetTombaId] = useState<any[]>([]);
    const [idSettore, setIdSettore] = useState<number | undefined>(undefined);
    const [active, setActive] = React.useState<"dati" | "grafica">("grafica");
    const { idCimitero } = useParams();

    const {
        data: settore,
        loading: loadingSettore,
        run,
        mutate,
        error,
    } = useRequest(getSettoreCimitero, { manual: true });

    const { data: tombe, loading: loadingTombe, run: runTombe } = useCruxRequest(getTombeSettore, { manual: true });
    const { run: runGrafiche } = useCruxRequest(getGrafica, { manual: true });
    const { handleApiError } = useCruxToaster();

    const handleSelectSettore = (settoreCimitero: SettoreCimitero): void => {
        setIdSettore(settoreCimitero.id);
        run(settoreCimitero.id, active === "grafica");
        runTombe(settoreCimitero.id, active === "grafica");
    };

    const handleSetIdSettore = (s: SettoreCimitero) => {
        if (s) {
            setIdSettore(s.id);
            mutate(undefined);
            runTombe(s.id, active === "grafica");
        }
    };

    const navigateToTomba = (st: Tomba) => {
        setGetTombaId([st.id]);
    };

    const navigateToSettore = (sc: SettoreCimitero) => {
        setIdSettore(sc.id);
        run(sc.id, active === "grafica");
        runTombe(sc.id, active === "grafica");
    };

    const handleReload = () => {
        if (settore) {
            runTombe(settore.id, active === "grafica");
            runGrafiche(settore.id, [3, 4]);
            if (idSettore) {
                run(idSettore, active === "grafica");
            } else if (idCimitero) {
                run(idCimitero, active === "grafica");
            }
        }
    };

    const findTomba = useMemo(() => {
        return getTombaId.length === 1 ? tombe?.find((value) => value.id === getTombaId[0]) : undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTombaId]);

    useEffect(() => {
        if (findTomba && setGetTomba) setGetTomba(findTomba);
        if (setTombe) setTombe(getTombaId);
    }, [findTomba, setGetTomba, getTombaId, setTombe]);

    useEffect(() => {
        if (active === "grafica" && idSettore) {
            run(idSettore, true);
            runTombe(idSettore, true);
        }
    }, [active]);

    useEffect(() => {
        if (error) {
            handleApiError({
                error: (error as any).response.data,
            });
        }
    }, [error]);

    return (
        <section className='h-full flex space-x-20'>
            <div className='w-[26rem] bg-white shadow-xl rounded-md'>
                <div className='h-full bg-[#2D3D60] pt-12'>
                    <SelezionaSettore
                        selectedIdSettore={idSettore}
                        onSettoreSelected={handleSelectSettore}
                        className='trasferimentoTree'
                    />
                </div>
            </div>

            <div className='w-3/4 flex items-center h-fit flex-col'>
                {!disabledGrafica && (
                    <Nav activeKey={active} onSelect={setActive} className='w-full' justified appearance='subtle'>
                        <Nav.Item eventKey='dati'>Dati</Nav.Item>
                        <Nav.Item eventKey='grafica'>Grafica</Nav.Item>
                    </Nav>
                )}

                {(active === "dati" || (!disabledGrafica && active !== "grafica")) && (
                    <section className='h-trasferimentoDrawer overflow-y-auto w-full py-12'>
                        {settore?.settori && (tombe?.length === 0 || !tombe) && (
                            <SottoSettoriViewWrapper
                                settore={settore}
                                isLoading={loadingSettore}
                                setSettore={handleSetIdSettore}
                            />
                        )}

                        {tombe && tombe?.length > 0 && (
                            <TombeSettoreViewWrapper
                                tombe={tombe ?? []}
                                isLoading={loadingTombe}
                                setGetTombaId={setGetTombaId}
                                searchMode
                                multipleOptions={multipleOptions}
                            />
                        )}

                        {!settore?.settori && !tombe && (
                            <h2 className='font-bold text-md text-center'>Nessun settore selezionato</h2>
                        )}
                    </section>
                )}

                {active === "grafica" && (
                    <section className='h-trasferimentoDrawer overflow-y-auto w-full py-12'>
                        {settore && (
                            <MapProvider>
                                <GraphicView
                                    settore={settore}
                                    tombe={tombe ?? []}
                                    loadingSettore={loadingSettore}
                                    loadingTombe={loadingTombe}
                                    onSelectTomba={navigateToTomba}
                                    onSelectSettore={navigateToSettore}
                                    handleReload={handleReload}
                                    trasferimentoMap
                                />
                            </MapProvider>
                        )}

                        {!settore && <h2 className='font-bold text-md text-center'>Nessuna grafica selezionata</h2>}
                    </section>
                )}
            </div>
        </section>
    );
};

export default SelezionaTomba;
