import axios from "axios";

import { setupInterceptorsTo } from "./interceptors";

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
);

export { api };
