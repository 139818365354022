/* eslint-disable jsx-a11y/media-has-caption */
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

const PublicLayout = () => {
    const { pathname } = useLocation();

    const isSearchPage = useMemo(() => {
        return (
            pathname.includes("ricerca/totem") ||
            pathname.includes("ricerca/defunti") ||
            pathname.includes("ricerca/contratti")
        );
    }, [pathname]);

    return (
        <section className='flex h-screen'>
            <div
                className={`h-full transition-all shadow-lg ${isSearchPage ? "w-full" : "w-full xl:w-1/3 p-20 px-80"}`}
            >
                <Outlet />
            </div>
            {!isSearchPage && (
                <div className='h-full w-full flex-1 relative overflow-hidden'>
                    <video loop autoPlay muted className='w-full h-fit'>
                        <source
                            src='https://ik.imagekit.io/z0o6dyhsx/tr:w-1920,h-1380/CruxLogin.mp4?updatedAt=1706096948700'
                            type='video/mp4'
                        />
                    </video>
                </div>
            )}
        </section>
    );
};

export default PublicLayout;
