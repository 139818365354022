import { format } from "date-fns";
import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import Overlay from "ol/Overlay";
import { Geometry } from "ol/geom";
import OLVectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { StyleLike } from "ol/style/Style";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ELayer } from "@models/Grafica";
import { EStato } from "@models/Tomba";

import { featureLayer, featureProperties } from "@openLayersMap/Helpers/EditMappingHelpers";
import { getPrimoDefunto } from "@openLayersMap/Helpers/MappingHelpers";

import { useMap } from "../Map/MapContext";

interface VectorLayerProps {
    source: VectorSource;
    style?: StyleLike;
}

const createMagnifyElement = (feature: FeatureLike, layer: ELayer) => {
    const properties = featureProperties(feature as Feature<Geometry>);
    const element = document.createElement("div");
    const hasPrenotazione = properties?.properties?.element?.posti?.some((p: any) => p?.stato === EStato.Prenotato);

    const primoDefunto = getPrimoDefunto(properties?.properties?.element?.posti);
    const codice = layer === ELayer.Tomba ? properties?.name : properties?.properties?.element?.codice;
    const note = properties?.properties?.element?.note ? `Note: ${properties?.properties?.element?.note}` : "";

    const cognomeENome =
        primoDefunto?.cognome || primoDefunto?.nome ? `${primoDefunto?.cognome} ${primoDefunto?.nome}` : "";
    const dataMorte = primoDefunto?.dataMorte ? primoDefunto?.dataMorte : "";
    const sfondo = properties?.properties?.element?.colore?.sfondo;

    element.classList.add("magnify-element");

    element.style.backgroundColor = !hasPrenotazione ? sfondo : "#e5e5e5";
    element.style.border = `1px solid ${properties?.properties?.element?.colore?.bordo}`;

    const divNode = document.createElement("p");
    divNode.textContent = `Numero tomba: ${codice}`;
    element.appendChild(divNode);

    const pNodeCognomeENome = document.createElement("p");
    pNodeCognomeENome.textContent = `${hasPrenotazione ? "Prenotato per:" : "Defunto:"} ${cognomeENome}`;
    element.appendChild(pNodeCognomeENome);

    const pNodeDataMorte = document.createElement("p");
    pNodeDataMorte.textContent = `Data morte: ${dataMorte || "-"}`;
    element.appendChild(pNodeDataMorte);

    const unicodeElementIcone = document.createElement("p");
    const icone =
        properties?.properties?.element?.icone && properties?.properties?.element?.icone?.length > 0
            ? properties.properties.element.icone
                  .filter((c: any) => c)
                  .map((i: string) => {
                      return i.replace("0x", "&#x");
                  })
            : undefined;

    if (icone) {
        unicodeElementIcone.innerHTML = icone.toString().replaceAll(",", " ");
        unicodeElementIcone.classList.add("fa");
        unicodeElementIcone.style.fontFamily = "Font Awesome 6 Pro";
        unicodeElementIcone.style.fontSize = "1rem";
        element.appendChild(unicodeElementIcone);
    }

    const contratti =
        properties?.properties?.element?.contratti && properties?.properties?.element?.contratti?.length > 0
            ? properties.properties.element.contratti.map((c: any) => {
                  if (c?.data && !c?.dataScadenza)
                      return `<li>${c?.anno}/${c?.numero} del ${format(new Date(c?.data), "dd/MM/yyyy")}</li>`;
                  if (c?.data && c?.dataScadenza)
                      return `<li>${c?.anno}/${c?.numero} del ${format(new Date(c?.data), "dd/MM/yyyy")} al ${format(
                          new Date(c?.dataScadenza),
                          "dd/MM/yyyy"
                      )}</li>`;

                  return `<li>${c?.anno}/${c?.numero}</li>`;
              })
            : undefined;

    if (contratti) {
        const pNodeContrattiTitle = document.createElement("p");
        const pNodeContratti = document.createElement("ul");

        pNodeContrattiTitle.textContent = "Contratti:";
        pNodeContrattiTitle.classList.add("magnify-contratti-title");
        pNodeContratti.innerHTML = contratti.toString().replaceAll(",", " ");
        pNodeContratti.classList.add("magnify-contratti");
        element.appendChild(pNodeContrattiTitle);
        element.appendChild(pNodeContratti);
    }

    const pNodeNote = document.createElement("p");
    pNodeNote.textContent = note;
    element.appendChild(pNodeNote);

    return element;
};

const VectorLayer: FC<VectorLayerProps> = ({ source, style }) => {
    const { idCimitero } = useParams();
    const { map } = useMap();

    useEffect(() => {
        if (!map) return undefined;

        const vectorLayer = new OLVectorLayer({
            source,
            style,
        });

        const magnifyOverlay = new Overlay({
            element: document.createElement("div"),
            stopEvent: false,
        });
        map.addOverlay(magnifyOverlay);

        const handlePointerMove = (event: any) => {
            const feature = map.forEachFeatureAtPixel(event.pixel, (f) => f);
            if (feature) {
                const layer = featureLayer(feature as Feature<Geometry>);
                const geometry = feature.getGeometry();
                const extent = geometry?.getExtent();

                if (extent && geometry && layer === ELayer.Tomba && idCimitero) {
                    // const center = getCenter(extent);
                    const view = map.getView();
                    const mapExtent = view.calculateExtent(map.getSize());
                    const topLeft = [mapExtent[0] + 0.4, mapExtent[3] - 1];

                    magnifyOverlay.setPosition(topLeft);
                    magnifyOverlay.setElement(createMagnifyElement(feature, layer));
                }
            } else {
                magnifyOverlay.setPosition(undefined);
            }
        };

        map.on("pointermove", handlePointerMove);

        map.addLayer(vectorLayer);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
                map.removeOverlay(magnifyOverlay);
                map.un("pointermove", handlePointerMove);
            }
        };
    }, [map, source, style]);

    return <></>;
};

export default VectorLayer;
