import { useThrottleFn } from "ahooks";
import { Message, useToaster } from "rsuite";

import { BaseResponse } from "@models/Responses/BaseResponse";

export const useCruxToaster = () => {
    const toaster = useToaster();

    const handleApiError = ({ error, message }: { error?: BaseResponse<unknown>; message?: string }) => {
        const title = message ?? error?.meta?.messaggio ?? "Errore generico";
        toaster.push(
            <Message showIcon type='error' closable header={title}>
                {!!error &&
                    error?.meta?.errori.map((value, index) => {
                        return <p key={index} dangerouslySetInnerHTML={{ __html: value ?? "" }} />;
                    })}
            </Message>,
            { placement: "topCenter", duration: 10000 }
        );

        return Promise.reject(error);
    };

    const handleApiSuccess = ({ message }: { message?: string }) => {
        toaster.push(
            <Message showIcon type='success'>
                <div dangerouslySetInnerHTML={{ __html: message ?? "" }} />
            </Message>,
            { placement: "topEnd" }
        );
    };

    const handleApiSuccessDismiss = ({ message }: { message?: string }) => {
        toaster.push(
            <Message showIcon closable type='success'>
                <div dangerouslySetInnerHTML={{ __html: message ?? "" }} />
            </Message>,
            { placement: "topEnd", duration: 50000 }
        );
    };

    const { run: runWarningThrottled } = useThrottleFn(
        (message) => {
            toaster.push(
                <Message showIcon type='warning'>
                    {message}
                </Message>,
                { placement: "topEnd" }
            );
        },
        { wait: 2000 }
    );

    const handleApiWarning = ({ message }: { message?: string }) => {
        runWarningThrottled(message);
    };

    return {
        handleApiError,
        handleApiSuccess,
        handleApiSuccessDismiss,
        handleApiWarning,
    };
};
