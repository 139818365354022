import { toNumber } from "lodash";

import { api } from "@services/api/api";

import { ELayer, IGrafica } from "@models/Grafica";
import { BaseResponse } from "@models/Responses/BaseResponse";
import {
    IAddPostiTomba,
    IAnnullaTrasferimentoDefunto,
    IDeletePostoTomba,
    IGetPostoTomba,
    ILiberaPostoTomba,
    ITrasferimentoDefunto,
    IUpdatePostiTomba,
    IUpdatePostoTomba,
    SettoreCimitero,
} from "@models/SettoreCimitero";
import { IAddTomba, IStrutturaLivelli } from "@models/StrutturaLivelli";
import { IPostoTombaExtended, PathItem, RUpdatePostiTomba, TipiTrasferimento, Tomba } from "@models/Tomba";

const BASE_PATH = "/struttura";
const BASE_PATH_TOMBE = "/tombe";
const BASE_PATH_TABELLE = "/tabelle";

const getCimiteri = async () => {
    return api
        .get<BaseResponse<SettoreCimitero[]>>(`${BASE_PATH}`, {
            params: {
                includiFigli: false,
                livello: 1,
            },
        })
        .then((res) => res.data?.data);
};

const getFigliSettoreCimitero = async (idCimitero: number | string) => {
    return api
        .get<BaseResponse<SettoreCimitero[]>>(`${BASE_PATH}`, {
            params: {
                idPadre: idCimitero,
                includiFigli: true,
            },
        })
        .then((res) => res.data?.data);
};

const getSettoreCimitero = async (idSettore: number | string | undefined, includiGrafica: boolean = false) => {
    return api
        .get<BaseResponse<SettoreCimitero[]>>(`${BASE_PATH}`, {
            params: {
                idPadre: idSettore,
                includiPadre: true,
                soloFigli: true,
                includiStat: true,
                includiGrafica,
            },
        })
        .then((res) => res.data?.data[0]);
};

const getAllSettori = async () => {
    return api
        .get<BaseResponse<SettoreCimitero[]>>(`${BASE_PATH}`, {
            params: {
                includiFigli: true,
            },
        })
        .then((res) => res.data?.data);
};

const addSettore = async ({ payload }: { payload: Partial<IStrutturaLivelli> }) => {
    const { idSettore, ...rest } = payload;

    return api
        .post<BaseResponse<SettoreCimitero>>(`${BASE_PATH}`, {
            ...rest,
            idPadre: idSettore ?? 0,
        })
        .then((res) => res.data?.data);
};

const addTomba = async ({ idSettore, payload }: { idSettore: number; payload: IAddTomba[] }) => {
    return api
        .post<BaseResponse<Tomba[]>>(`${BASE_PATH}/${idSettore}/aggiungi-tomba`, {
            data: payload,
        })
        .then((res) => res.data?.data);
};

const deleteTomba = async ({ idSettore, idTomba }: { idSettore: number; idTomba: number }) => {
    return api
        .delete<BaseResponse<Tomba[]>>(`${BASE_PATH}/${idSettore}/elimina-tomba/${idTomba}`)
        .then((res) => res.data?.data);
};

const disattivaCanoniTomba = async ({ dataDisattivazione, idTomba }: { dataDisattivazione: Date; idTomba: number }) => {
    return api
        .put<BaseResponse<number>>(`${BASE_PATH_TOMBE}/${idTomba}/disattiva-canoni`, { dataDisattivazione })
        .then((res) => res.data?.data);
};

const updateSettore = async ({ payload }: { payload: Partial<IStrutturaLivelli> }) => {
    const { idPadri, idSettore, ...rest } = payload;

    return api
        .put<BaseResponse<SettoreCimitero>>(`${BASE_PATH}/${payload.idSettore}`, {
            ...rest,
            idPadre: !!idPadri && idPadri.length > 0 ? toNumber(idPadri.at(-1)) : 0,
        })
        .then((res) => res.data?.data);
};

const deleteSettore = async ({ idSettore }: { idSettore: number }) => {
    return api.delete<BaseResponse<number>>(`${BASE_PATH}/${idSettore}?recursive=true`).then((res) => res.data?.data);
};

const getLastGrave = async ({ idSettore }: { idSettore: number }) => {
    return api
        .get<BaseResponse<string | number>>(`${BASE_PATH}/${idSettore}/ultima-tomba`)
        .then((res) => res.data?.data);
};

const getTombeSettore = async (idSettore: number, includiGrafica: boolean = false) => {
    return api
        .get<BaseResponse<Tomba[]>>(`${BASE_PATH}/${idSettore}/tombe`, {
            params: { includiGrafica },
        })
        .then((res) => res.data);
};

const getGrafica = async (
    idSettore: number,
    layers: number[] = [ELayer.Settore, ELayer.Tomba, ELayer.Testo, ELayer.Retino]
) => {
    return api
        .get<BaseResponse<IGrafica[]>>(`${BASE_PATH}/${idSettore}/grafica`, {
            params: {
                layers: layers.join(","),
            },
        })
        .then((res) => res.data);
};

const updateGrafica = async (idSettore: number, payload: IGrafica[]) => {
    return api
        .post<BaseResponse<IGrafica[]>>(`${BASE_PATH}/${idSettore}/grafica`, {
            data: payload,
        })
        .then((res) => res.data);
};

const deleteGrafica = async ({ idSettore, idGrafiche }: { idSettore: number; idGrafiche: number[] }) => {
    return api
        .delete<BaseResponse<{ id: number; layer: number }[]>>(`${BASE_PATH}/${idSettore}/grafica`, {
            data: {
                ids: idGrafiche,
            },
        })
        .then((res) => res.data);
};

const dimensioneTestoGrafica = async ({
    idSettore,
    dimensione,
    layers = [ELayer.Settore, ELayer.Tomba, ELayer.Testo, ELayer.Retino],
}: {
    idSettore: number;
    dimensione: number;
    layers: number[];
}) => {
    return api
        .put<BaseResponse<{ id: number; layer: number }[]>>(
            `${BASE_PATH}/${idSettore}/grafica/dimensione-testo?dimensione=${dimensione}&layers=${layers.join(",")}`
        )
        .then((res) => res.data);
};

const getTomba = async (idTomba: number) => {
    return api.get<BaseResponse<Tomba>>(`${BASE_PATH_TOMBE}/${idTomba}?storico=true`).then((res) => res.data);
};

const updateTomba = async ({ idTipo, idTomba, numero, note, icona }: Partial<IUpdatePostiTomba>) => {
    return api
        .put<BaseResponse<RUpdatePostiTomba>>(`${BASE_PATH_TOMBE}/${idTomba}`, {
            idTipo,
            numero,
            note,
            icona,
        })
        .then((res) => res.data);
};

const addPostiTomba = async ({ idTomba, postiResto, postiSalma }: Partial<IAddPostiTomba>) => {
    return api
        .post<BaseResponse<Tomba>>(`${BASE_PATH_TOMBE}/${idTomba}/aggiungi-posti`, {
            postiResto: (!!postiResto && +postiResto) || 0,
            postiSalma: (!!postiSalma && +postiSalma) || 0,
        })
        .then((res) => res.data);
};

const getTipiTrasferimento = async () => {
    return api
        .get<BaseResponse<TipiTrasferimento[]>>(`${BASE_PATH_TABELLE}/tipi-trasferimento`)
        .then((res) => res.data);
};

const trasferimentoPostoTomba = async ({
    idTomba,
    idPosto,
    idTipoTrasferimento,
    direzione,
    data,
    ora,
    comuneDestinazione,
    idPostoOrigine,
    idTombaOrigine,
    tipoDefuntoDestinazione,
}: Partial<ITrasferimentoDefunto>) => {
    return api
        .post<BaseResponse<Tomba>>(`${BASE_PATH_TOMBE}/${idTombaOrigine}/posti/${idPostoOrigine}/trasferisci`, {
            idTipoTrasferimento,
            direzione,
            data,
            ora,
            comuneDestinazione,
            idTomba: idTomba && +idTomba,
            idPosto,
            ...(tipoDefuntoDestinazione && { tipoDefuntoDestinazione }),
        })
        .then((res) => res.data);
};

const annullaTrasferimentoPostoTomba = async ({ idTombaOrigine, idPostoOrigine }: IAnnullaTrasferimentoDefunto) => {
    return api
        .delete<BaseResponse<any>>(`${BASE_PATH_TOMBE}/${idTombaOrigine}/posti/${idPostoOrigine}/annulla-trasferimento`)
        .then((res) => res.data);
};

const getPostoTomba = async ({ idTomba, idPosto }: IGetPostoTomba) => {
    return api
        .get<BaseResponse<IPostoTombaExtended>>(`${BASE_PATH_TOMBE}/${idTomba}/posti/${idPosto}`)
        .then((res) => res.data.data);
};

const updatePostoTomba = async ({ idTomba, idPosto, data }: IUpdatePostoTomba) => {
    return api
        .put<BaseResponse<IPostoTombaExtended>>(`${BASE_PATH_TOMBE}/${idTomba}/posti/${idPosto}`, data)
        .then((res) => res.data.data);
};

const deletePostoTomba = async ({ idTomba, idPosto }: IDeletePostoTomba) => {
    return api.delete<BaseResponse<Tomba>>(`${BASE_PATH_TOMBE}/${idTomba}/posti/${idPosto}`).then((res) => res.data);
};

const liberaPostoTomba = async ({ idTomba, idPosto, eliminaNote }: ILiberaPostoTomba) => {
    return api
        .put<BaseResponse<Tomba>>(
            `${BASE_PATH_TOMBE}/${idTomba}/posti/${idPosto}/libera`,
            {},
            { params: { eliminaNote } }
        )
        .then((res) => res.data);
};

const searchInSettoreCimitero = async (idSettore: number | string | undefined, searchParams: any) => {
    //todo -> come sarà????
    return api.get<BaseResponse<Tomba[]>>(`${BASE_PATH}/${idSettore}/tombe`).then((res) => res.data?.data);
};

const searchPath = async (idTomba: number) => {
    return api.get<BaseResponse<PathItem[]>>(`${BASE_PATH_TOMBE}/${idTomba}/path`).then((res) => res.data);
};

export {
    getCimiteri,
    getFigliSettoreCimitero,
    getAllSettori,
    getSettoreCimitero,
    getLastGrave,
    addSettore,
    addTomba,
    deleteTomba,
    disattivaCanoniTomba,
    updateSettore,
    deleteSettore,
    getTombeSettore,
    getTipiTrasferimento,
    getGrafica,
    updateGrafica,
    deleteGrafica,
    getTomba,
    updateTomba,
    addPostiTomba,
    getPostoTomba,
    updatePostoTomba,
    deletePostoTomba,
    trasferimentoPostoTomba,
    annullaTrasferimentoPostoTomba,
    liberaPostoTomba,
    searchInSettoreCimitero,
    searchPath,
    dimensioneTestoGrafica,
};
