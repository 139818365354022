import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { CheckboxGroup, RadioGroup } from "rsuite";

import { SettoreCimitero } from "@models/SettoreCimitero";
import { Tomba } from "@models/Tomba";

import withLoading from "@hoc/withLoading";

import TombaCard from "./TombaCard";

interface DataViewProps {
    tombe: Tomba[];
    settore?: SettoreCimitero | undefined;
    searchMode?: boolean;
    setSelectTomba?: (tomba: Tomba) => void;
    setGetTombaId?: Dispatch<SetStateAction<any[]>>;
    historicalData?: boolean;
    multipleOptions?: boolean;
}

interface IRowRenderer {
    data: Tomba[];
    index: number;
    setSize: any;
    searchMode?: boolean;
    historicalData?: boolean;
    setSelectTomba?: (tomba: Tomba) => void;
    tombaId: any[];
    setTombaId?: Dispatch<SetStateAction<any[]>>;
    multipleOptions?: boolean;
    handleReload?: () => void;
    defaultExpanded?: { [key: string]: boolean };
    setDefaultExpanded?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

const RowRenderer = ({
    data,
    index,
    setSize,
    searchMode,
    setSelectTomba,
    historicalData,
    setTombaId,
    tombaId,
    multipleOptions,
    defaultExpanded,
    setDefaultExpanded,
}: IRowRenderer) => {
    const rowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (rowRef.current) setSize(index, ((rowRef.current as any)?.getBoundingClientRect()?.height ?? 50) + 12);
    }, [setSize, index]);

    useEffect(() => {
        setTimeout(() => {
            setSize(index, ((rowRef.current as any)?.getBoundingClientRect()?.height ?? 50) + 12);
        }, 250);
    }, [defaultExpanded]);

    return (
        <div ref={rowRef}>
            {!multipleOptions ? (
                <RadioGroup
                    className='space-y-8'
                    name='tomba-select'
                    value={tombaId[0]}
                    onChange={(id: any) => {
                        if (setTombaId) setTombaId([id]);
                    }}
                >
                    <TombaCard
                        tomba={data[index]}
                        key={data[index].id}
                        searchMode={searchMode}
                        setSelectTomba={setSelectTomba}
                        historicalData={historicalData}
                        list
                        defaultExpanded={defaultExpanded}
                        setDefaultExpanded={setDefaultExpanded}
                    />
                </RadioGroup>
            ) : (
                <CheckboxGroup
                    value={tombaId}
                    onChange={(id: any) => {
                        if (setTombaId) setTombaId(id);
                    }}
                >
                    <TombaCard
                        tomba={data[index]}
                        key={data[index].id}
                        searchMode={searchMode}
                        setSelectTomba={setSelectTomba}
                        historicalData={historicalData}
                        list
                        multipleOptions
                        defaultExpanded={defaultExpanded}
                        setDefaultExpanded={setDefaultExpanded}
                    />
                </CheckboxGroup>
            )}
        </div>
    );
};

const TombeSettoreViewWrapper: FC<DataViewProps> = ({
    tombe,
    settore,
    searchMode,
    setSelectTomba,
    setGetTombaId,
    historicalData,
    multipleOptions,
}) => {
    const [defaultExpanded, setDefaultExpanded] = useState<{ [key: string]: boolean }>({});
    const [tombaId, setTombaId] = useState<any[]>([]);
    const listRef = useRef();
    const sizeMap = useRef<{ [key: number]: any }>({});
    const setSize = useCallback((index: any, size: any) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        (listRef.current as any)?.resetAfterIndex(index);
    }, []);

    const getSize = (index: number) => sizeMap.current[index] || 50;

    const handleTombaId = (id: any) => {
        setTombaId(id);
        if (setGetTombaId) setGetTombaId(id);
    };

    return (
        <List
            ref={listRef as any}
            height={tombe.length > 0 ? 400 : 0}
            itemCount={tombe.length}
            itemSize={getSize}
            width='100%'
            itemData={tombe}
            className={classNames(
                {
                    "h-virtualizedList mt-12 block overflow-y-auto":
                        tombe.length > 0 && (!settore?.settori || settore?.settori?.length === 0),
                },
                { "h-[18.5rem] mt-12 block": tombe.length > 0 && settore?.settori && settore?.settori?.length > 0 },
                { hidden: tombe.length === 0 }
            )}
        >
            {({ data, index, style }) => (
                <div style={style}>
                    <RowRenderer
                        data={data}
                        index={index}
                        setSize={setSize}
                        historicalData={historicalData}
                        setSelectTomba={setSelectTomba}
                        searchMode={searchMode}
                        setTombaId={handleTombaId}
                        tombaId={tombaId}
                        multipleOptions={multipleOptions}
                        defaultExpanded={defaultExpanded}
                        setDefaultExpanded={setDefaultExpanded}
                    />
                </div>
            )}
        </List>
    );
};

export default withLoading(TombeSettoreViewWrapper);
