import { IGrafica } from "@models/Grafica";
import { IPostoTombaExtended } from "@models/Tomba";

import Livello from "./Livello";

export interface IStat {
    codice: string;
    colore: string;
    descr: string;
    valore: string;
}

interface SettoreCimitero {
    id: number;
    codice?: string;
    descr?: string;
    descrCompleta: string;
    livello: Livello;
    visualizzaDescr?: boolean;
    visualizzaSuWeb?: boolean;
    settori?: SettoreCimitero[];
    settoriPadre?: { id: number; descrCompleta: string }[];
    stat?: IStat[];
    visible?: boolean;
    grafiche?: IGrafica[] | null;
}

interface IUpdatePostiTomba {
    idTomba: number | undefined;
    idTipo: number | undefined;
    numero: string | undefined;
    note: string | undefined;
    icona: string | undefined;
}

interface IAddPostiTomba {
    idTomba: number | undefined;
    postiSalma: number | undefined;
    postiResto: number | undefined;
}

interface IGetPostoTomba {
    idTomba: number | undefined;
    idPosto: number | undefined;
}

export type ICrudPostiTomba = Partial<IAddPostiTomba> & Partial<IUpdatePostiTomba> & Partial<ITrasferimentoDefunto>;

enum ETipoTrasferimento {
    Spostamento = 0,
    Accostamento = 1,
    Esumazione = 2,
}

enum EDirezione {
    Entrata = 0,
    Uscita = 1,
}

enum TipoDefuntoDestinazione {
    CENERI = "C",
    RESTI = "R",
}

interface ITrasferimentoDefunto {
    direzione?: EDirezione | undefined;
    data?: string | Date | undefined;
    ora?: string;
    comuneDestinazione?: string | undefined;
    idPosto?: number | undefined;
    idTomba?: number | string | undefined;
    idPostoOrigine?: number | undefined;
    idTombaOrigine?: number | undefined;
    idTipoTrasferimento?: ETipoTrasferimento;
    tipoDefuntoDestinazione?: TipoDefuntoDestinazione | string;
    trasferimentoEvent?: string;
}

interface IAnnullaTrasferimentoDefunto {
    idTombaOrigine: number;
    idPostoOrigine: number;
}

interface IUpdateDataPostoTomba extends Omit<Partial<IPostoTombaExtended>, "anagrafica"> {
    idAnag: number;
}

interface IUpdatePostoTomba {
    idTomba: number | undefined;
    idPosto: number | undefined;
    data: IUpdateDataPostoTomba;
}

interface IDeletePostoTomba {
    idTomba: number | undefined;
    idPosto: number | undefined;
}

interface ILiberaPostoTomba {
    idTomba: number | undefined;
    idPosto: number | undefined;
    eliminaNote: boolean;
}

export type {
    SettoreCimitero,
    IUpdatePostiTomba,
    IAddPostiTomba,
    IDeletePostoTomba,
    ILiberaPostoTomba,
    IGetPostoTomba,
    IUpdatePostoTomba,
    IUpdateDataPostoTomba,
    ITrasferimentoDefunto,
    IAnnullaTrasferimentoDefunto,
};

export { EDirezione, ETipoTrasferimento, TipoDefuntoDestinazione };
