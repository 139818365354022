import {
    faClose,
    faDownload,
    faExternalLinkAlt,
    faEye,
    faPenToSquare,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBytes } from "@mocks/helpers";
import { format, parseISO } from "date-fns";
import FileDownload from "js-file-download";
import React, { FC, useMemo, useState } from "react";
import { ButtonToolbar, FlexboxGrid, IconButton, List, Modal } from "rsuite";

import { downloadFile, previewFile } from "@services/FileService";

import File from "@models/File";

import { useCruxToaster } from "@hooks/useCruxToaster";

interface FileListItemProps {
    item: File;
    onDelete: () => void;
    onUpdate: () => void;
}

const FileListItem: FC<FileListItemProps> = ({ item, onDelete, onUpdate }) => {
    const [preview, setPreview] = useState<string | null>(null);
    const { handleApiError } = useCruxToaster();

    const handlePreview = () => {
        previewFile(item.tipo, item.uId, true)
            .then(setPreview)
            .catch(async (apiError) => {
                const errorBlob = JSON.parse(await apiError?.response?.data.text());
                handleApiError({
                    message:
                        errorBlob?.meta?.errori.map((err: string) => err) ??
                        "Errore nella visualizzazione del documento",
                });
            });
    };
    const handleOpen = () => {
        previewFile(item.tipo, item.uId)
            .then((fileURL) => window.open(fileURL))
            .catch(async (apiError) => {
                const errorBlob = JSON.parse(await apiError?.response?.data.text());
                handleApiError({
                    message:
                        errorBlob?.meta?.errori.map((err: string) => err) ??
                        "Errore nella visualizzazione del documento",
                });
            });
    };
    const handleDownload = () => {
        downloadFile(item.tipo, item.uId)
            .then((resp) => {
                FileDownload(resp, item.fileName);
            })
            .catch((_) => {
                handleApiError({ message: "Errore nel download del file" });
            });
    };

    const isImage = useMemo(() => {
        return (
            item.fileName.toLocaleLowerCase().includes("jpg") ||
            item.fileName.toLocaleLowerCase().includes("png") ||
            item.fileName.toLocaleLowerCase().includes("jpeg")
        );
    }, [item.fileName]);

    const isPdf = useMemo(() => {
        return item.fileName.toLocaleLowerCase().includes("pdf");
    }, [item.fileName]);

    const isDoc = useMemo(() => {
        return item.fileName.toLocaleLowerCase().includes("odt") || item.fileName.toLocaleLowerCase().includes("doc");
    }, [item.fileName]);

    return (
        <List.Item>
            <FlexboxGrid className='mx-8'>
                <FlexboxGrid.Item colspan={9} className='block h-fit relative'>
                    <p className='text-ellipsis overflow-hidden'> {item.descr}</p>
                    <p className='text-ellipsis overflow-hidden'>{item.fileName}</p>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={3} className='flex justify-end items-start h-[1.875rem] flex-col'>
                    <p className='text-ellipsis overflow-hidden'>
                        {item?.fileSize && item?.fileSize !== 0 ? formatBytes(item.fileSize) : ""}
                    </p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3} className='flex justify-end items-start h-[1.875rem] flex-col'>
                    <p className='text-ellipsis overflow-hidden'>
                        {item?.dataUpload ? format(parseISO(item.dataUpload), "dd/MM/yyyy") : ""}
                    </p>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={9} className='flex justify-end items-center h-[1.875rem]'>
                    <ButtonToolbar>
                        {!isDoc && (
                            <IconButton
                                onClick={handlePreview}
                                icon={<FontAwesomeIcon icon={faEye} title='visualizza anteprima' />}
                                appearance='ghost'
                                size='xs'
                            />
                        )}
                        {!isDoc && (
                            <IconButton
                                onClick={handleOpen}
                                icon={
                                    <FontAwesomeIcon
                                        icon={faExternalLinkAlt}
                                        title='visualizza in una nuova finestra'
                                    />
                                }
                                appearance='ghost'
                                size='xs'
                            />
                        )}
                        <IconButton
                            onClick={handleDownload}
                            icon={<FontAwesomeIcon icon={faDownload} />}
                            title='download file'
                            appearance='ghost'
                            size='xs'
                        />
                        <IconButton
                            onClick={onUpdate}
                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                            title='modifica file'
                            appearance='ghost'
                            size='xs'
                        />
                        <IconButton
                            onClick={onDelete}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            title='elimina file'
                            appearance='ghost'
                            size='xs'
                        />
                    </ButtonToolbar>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <Modal open={preview !== null} onClose={() => setPreview(null)} size='full'>
                <Modal.Body className='m-0 relative block h-full max-h-full overflow-hidden'>
                    <div className='flex w-full justify-end pb-12 '>
                        <FontAwesomeIcon
                            icon={faClose}
                            size='2x'
                            onClick={() => setPreview(null)}
                            className='cursor-pointer'
                        />
                    </div>

                    {preview && isImage && (
                        <img src={preview} alt='anteprima immagine' className='object-contain w-full h-full' />
                    )}

                    {isPdf && (
                        <object className='w-full h-modalContent object-cover overflow-hidden' data={preview ?? ""}>
                            La preview non è visualizzabile per questo tipo di files
                        </object>
                    )}
                </Modal.Body>
            </Modal>
        </List.Item>
    );
};
export default FileListItem;
