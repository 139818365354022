import NumberFormat from "react-number-format";

const EuroFormat = ({ value }: { value?: string | number }) => {
    return (
        <NumberFormat
            value={value}
            displayType='text'
            decimalSeparator='.'
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=','
            suffix='€ '
        />
    );
};

export default EuroFormat;
